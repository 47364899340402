html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=6a8c8c7d-2d9a-43e2-9c16-fd81bee094b3");
@font-face {
  font-family:"Avenir LT";
  font-weight: 300;
  src:url("../public/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
  src:url("../public/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("../public/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("../public/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("../public/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype");
}
@font-face {
  font-family:"Avenir LT";
  font-weight: 400;
  font-style: normal;
  src:url("../public/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
  src:url("../public/fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("../public/fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("../public/fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("../public/fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
}
@font-face {
  font-family:"Avenir LT";
  font-weight: 400;
  font-style: italic;
  src:url("../public/fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix");
  src:url("../public/fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix") format("eot"),url("../public/fonts/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),url("../public/fonts/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff"),url("../public/fonts/04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf") format("truetype");
}
@font-face {
  font-family:"Avenir LT";
  font-weight: 500;
  font-style: normal;
  src:url("../public/fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix");
  src:url("../public/fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix") format("eot"),url("../public/fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),url("../public/fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff"),url("../public/fonts/9bdf0737-f98c-477a-9365-ffc41b9d1285.ttf") format("truetype");
}
@font-face {
  font-family:"Avenir LT";
  font-weight: 700;
  font-style: normal;
  src:url("../public/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
  src:url("../public/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("../public/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("../public/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("../public/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype");
}
@font-face {
  font-family:"Bodoni72";
  src:url("../public/fonts/fa098ee9-946d-47f5-9673-0154e46d5ac1.eot?#iefix");
  src:url("../public/fonts/fa098ee9-946d-47f5-9673-0154e46d5ac1.eot?#iefix") format("eot"),url("../public/fonts/ff1850e0-22a8-4229-87a9-18ed2bdaab26.woff2") format("woff2"),url("../public/fonts/8c76cabf-4c55-440d-aa82-17fb5b063036.woff") format("woff"),url("../public/fonts/df2374a6-6777-4774-8dee-57c075a131db.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app_background {
  z-index: 0;

}


